import { curesUrl } from '../utils/constants';
import { ErrorUtils } from '../utils/exceptions';
import { AxiosBackendHelper } from '../utils/AxiosHelper';
import { newAxiosCache } from './cache';
import { CURESError } from './CURESError';

const axiosInstance = ErrorUtils.configureAxiosForMintErrors(AxiosBackendHelper.newInstance());

const infoByPrescriberCache = newAxiosCache(axiosInstance);
const infoByPharmacistCache = newAxiosCache(axiosInstance);
const curesUserCache = newAxiosCache(axiosInstance);

export default class CuresAPI {
  static async getInfoByPrescriber(
    patientId,
    prescriberNPI,
    prescriberSLN,
    prescriberFamilyName,
    prescriberGivenName,
    activityStartDate,
    activityEndDate,
    ignoreAddress = 'Y'
  ) {
    const result = await infoByPrescriberCache.get(
      `${curesUrl}/byPrescriber`,
      {
        params: {
          patientId,
          prescriberNPI,
          prescriberSLN,
          prescriberFamilyName,
          prescriberGivenName,
          activityStartDate,
          activityEndDate,
          ignoreAddress
        }
      },
    );
    console.log("DEBUG getInfoByPrescriber result: ", result);
    return result?.data;
  }

  static async getInfoByPharmacist(
    patientId,
    pharmacistSLN,
    pharmacyName,
    pharmacistFamilyName,
    pharmacistGivenName,
    activityStartDate,
    activityEndDate,
    ignoreAddress = 'Y'
  ) {
    const result = await infoByPharmacistCache.get(
      `${curesUrl}/byPharmacist`,
      {
        params: {
          patientId,
          pharmacistSLN,
          pharmacyName,
          pharmacistFamilyName,
          pharmacistGivenName,
          activityStartDate,
          activityEndDate,
          ignoreAddress
        }
      },
    );
    console.log("DEBUG getInfoByPharmacist result: ", result);
    return result?.data;
  }

  static async saveCuresUser(userData) {
    const result = await axiosInstance.post(
      `${curesUrl}/saveCuresUser`,
      userData,
    );
    return userWithMethods(result?.data);
  }

  static async getCuresUser(userName) {
    let result;
    try {
      result = await curesUserCache.get(`${curesUrl}/getCuresUser`, { params: { userName } });
    }
    catch (error) {
      CURESError.throw(error);
    }
    return convertAndEnhance(result?.data);
  }

  static dumpTrace() {
    const caches = {
      infoByPrescriberCache: Object.fromEntries(infoByPrescriberCache.trace),
      infoByPharmacistCache: Object.fromEntries(infoByPharmacistCache.trace),
      curesUserCache: Object.fromEntries(curesUserCache.trace),
    };
    console.log({ caches });
  }
}

function isPrescriber() {
  return this['providerType'] === 'Prescriber';
}

function hasRequiredIDs() {
  const { providerData: { SLN, NPI } } = this;
  return SLN && this.isPrescriber() ? NPI : true;
}

function getMedicationInfo(patientId, activityStartDate, activityEndDate, ignoreAddress) {
  console.log("DEBUG getMedicationInfo this: ", this)
  const { providerData: { SLN, NPI, givenName, familyName, businessName } } = this;
  if (this.hasRequiredIDs() && familyName && givenName) {
    console.log("DEBUG hasRequiredIDs");
    if (this.isPrescriber()) {
      console.log("DEBUG isPrescriber");
      return CuresAPI.getInfoByPrescriber(
        patientId, NPI, SLN, familyName, givenName, activityStartDate, activityEndDate,
        ignoreAddress
      );
    }
    else if (businessName) {
      console.log("DEBUG businessName: ", businessName);
      return CuresAPI.getInfoByPharmacist(
        patientId, SLN, businessName, familyName, givenName, activityStartDate, activityEndDate,
        ignoreAddress
      );
    }
    else if (!businessName) {
      console.log("DEBUG: NO BUSINESS NAME");
    }
  }
  return Promise.resolve({ data: null });
}

function userWithMethods(user) {
  user.isPrescriber = isPrescriber.bind(user);
  user.hasRequiredIDs = hasRequiredIDs.bind(user);
  user.getMedicationInfo = getMedicationInfo.bind(user);
  return user;
}

function convertAndEnhance(user) {
  if (!user) {
    CURESError.throwUserIncomplete(null);
  }
  const { providerType, providerData } = user;
  const userId = providerData['userId'];
  const userIdType = providerData['userIdType'];
  if (userId && userIdType) {
    const { givenName, familyName, businessName } = providerData;
    const newUser = {
      providerType,
      providerData: {
        givenName,
        familyName,
        [userIdType]: userId,
        businessName,
      }
    };
    CURESError.throwUserIncomplete(userWithMethods(newUser));
  }
  return userWithMethods(user);
}
