import _ from "lodash";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useHistory, useParams, withRouter } from "react-router-dom";
import { Context } from "../../../store/AppStore";
import styles from "../../../styles/viewer.module.css";
import {
  AssessmentsActionButton,
  AssessmentsActions,
} from "./AssessmentsActions";
import AssessmentsList from "./AssessmentsList";
import NewAssessmentDialog from "./NewAssessmentDialog";

const AssessmentsViewer = () => {
  const history = useHistory();
  const dispatch = useContext(Context)[1];
  const { patientId } = useParams();
  const [availableAssignments, setAvailableAssessments] = useState([]);
  const [assessments, setAssessments] = useState([]);
  const [assessmentDialogOpen, setAssessmentDialogOpen] = useState(false);

  const handleNewAssessmentDialogClick = () => {
    setAssessmentDialogOpen(true);
  };

  const handleNewAssessmentNewTabClick = () => {
    window.open(process.env.REACT_APP_TEST_ASSESSMENT, '_blank')
  };

  const handleNewAssessmentDialogClose = () => {
    setAssessmentDialogOpen(false);
  };

  const handleNewAssessmentDialogSave = () => {};

  return (
    <div className={styles.documentViewer}>
      {assessmentDialogOpen ? (
        <NewAssessmentDialog
          open={assessmentDialogOpen}
          handleClose={handleNewAssessmentDialogClose}
          assessmentId={1}
          token={"tokenTest"}
          onSurveyCompleted={handleNewAssessmentDialogSave}
        />
      ) : null}

      <AssessmentsActions>
        <AssessmentsActionButton
          text="New Assessment"
          action={handleNewAssessmentDialogClick}
        />
        <AssessmentsActionButton
          text="New Assessment on New Tab"
          action={handleNewAssessmentNewTabClick}
        />
      </AssessmentsActions>
      <AssessmentsList
        showAllAssessments={false}
        assessments={assessments}
        onAssessmentClick={() => console.log("click")}
      />
    </div>
  );
};

export default withRouter(AssessmentsViewer);
