import { getNormalizedPatientData } from '../utils';

let _patientData = null;

class PatientDataStore {

  async getPatientData(patientId, mrnCodingSystem = null) {
    console.log("DEBUG getPatientData mrnCodingSystem: ", mrnCodingSystem);
    if (!patientId) {
      _patientData = null;
    }
    else if (!_patientData || _patientData.id !== patientId) {
      _patientData = await getNormalizedPatientData(patientId, mrnCodingSystem)
    }
    return _patientData;
  }
}

export const patientDataStore = new PatientDataStore();
