import {
  fhirReplacementUrl,
  fhirUrlsToReplace,
  referralCapabilitiesIncludeAll,
} from "../utils/constants";
import { get } from "lodash";
import axiosInstance from "../utils/FhirAxiosHelper";
import { TenantConfigurationStore } from "../components/Tenants/TenantConfigurationStore";

export default class FhirReferralsAPI {
  static baseUrl = null;

  static async setBaseUrl() {
    const tenantConf = await TenantConfigurationStore.getTenantConfiguration();
    if (tenantConf?.fhirBaseUrl) {
      this.baseUrl = tenantConf?.fhirBaseUrl;
    }
  }

  static async getBaseUrl() {
    if (!this.baseUrl) {
      await this.setBaseUrl();
    }
    return this.baseUrl;
  }

  static async getReferrals(
    patientId,
    status,
    modifiedSince,
    entireOrganization
  ) {
    const baseUrl = await this.getBaseUrl();
    const url = `${baseUrl}/ServiceRequest`;
    const params = new URLSearchParams();

    if (patientId) {
      params.append("patient", patientId);
      params.append("_include", "ServiceRequest:performer");
      params.append("_include", "ServiceRequest:requester");
      params.append("_include", "ServiceRequest:subject");
    }
    const result = await axiosInstance.get(url, {
      params,
    });
    const referralsData = { referrals: [], organizations: [], patient: null };
    if (result?.data?.entry) {
      referralsData.referrals = result.data.entry
        ?.filter((r) => r?.resource?.resourceType === "ServiceRequest")
        ?.map((r) => r?.resource);
      referralsData.organizations = result.data.entry
        ?.filter((r) => r?.resource?.resourceType === "Organization")
        ?.map((r) => r?.resource);
      if (patientId) {
        referralsData.patient = await this.getPatientById(patientId);
      }
    } else if (patientId) {
      referralsData.patient = await this.getPatientById(patientId);
    }
    return referralsData;
  }

  static async getPatientById(patientId) {
    try {
      const baseUrl = await this.getBaseUrl();
      const url = `${baseUrl}/Patient?_id=${patientId}`;
      const result = await axiosInstance.get(url);
      const foundPatients = result?.data?.entry
        ?.filter((r) => r?.resource?.resourceType === "Patient")
        ?.map((r) => r?.resource);
      if (foundPatients && foundPatients.length > 0) {
        return foundPatients[0];
      }
    } catch (error) {
      console.log("DEBUG failed fetching patient");
      return null;
    }
    return null;
  }

  static async getPatientByIdentifier(patientIdentifier) {
    try {
      const baseUrl = await this.getBaseUrl();
      const url = `${baseUrl}/Patient?identifier=${patientIdentifier}`;
      const result = await axiosInstance.get(url);
      const foundPatients = result?.data?.entry
        ?.filter((r) => r?.resource?.resourceType === "Patient")
        ?.map((r) => r?.resource);
      if (foundPatients && foundPatients.length > 0) {
        return foundPatients[0];
      }
    } catch (error) {
      console.log("DEBUG failed fetching patient");
      return null;
    }
    return null;
  }

  static async getReferral(referralId, includeRelated = false) {
    if (includeRelated) {
      const url = `${await this.getBaseUrl()}/ServiceRequest?_id=${referralId}&_include=ServiceRequest:subject&_include=ServiceRequest:performer`;
      const result = await axiosInstance.get(url);
      const data = {
        referral: result?.data?.entry?.find(
          (d) => d?.resource?.resourceType === "ServiceRequest"
        ),
        patient: result?.data?.entry?.find(
          (d) => d?.resource?.resourceType === "Patient"
        ),
        organizations: result?.data?.entry
          ?.filter((d) => d?.resource?.resourceType === "Organization")
          ?.map((r) => r?.resource),
      };
      return data;
    } else {
      const url = `${await this.getBaseUrl()}/ServiceRequest/${referralId}`;
      const result = await axiosInstance.get(url);
      return result?.data;
    }
  }

  static async getOrganizations() {
    const url = `${await this.getBaseUrl()}/Organization`;
    const params = {};
    // if (patientId) {
    //   params["patient.identifier"] = patientId;
    //   params["_include"] = "ServiceRequest:performer";
    // }
    const result = await axiosInstance.get(url, {
      params,
    });
    const data = { organizations: [] };
    if (result?.data) {
      data.organizations = result.data.entry
        .filter((r) => r?.resource?.resourceType === "Organization")
        ?.map((r) => r?.resource);
    }
    return data;
  }

  static async createBinary(payload) {
    const url = `${await this.getBaseUrl()}/Binary`;
    const result = await axiosInstance.post(url, payload);
    return result?.data;
  }

  static async createDocumentReference(payload) {
    const url = `${await this.getBaseUrl()}/DocumentReference`;
    const result = await axiosInstance.post(url, payload);
    return result?.data;
  }

  static async createReferral(payload) {
    const url = `${await this.getBaseUrl()}/ServiceRequest`;
    const result = await axiosInstance.post(url, payload);
    return result?.data;
  }

  static async createPreventionLinkReferral(payload) {
    const url = `${await this.getBaseUrl()}/ServiceRequest`;
    const result = await axiosInstance.post(url, payload);
    return result?.data;
  }

  static async updateReferral(referralId, payload) {
    const url = `${await this.getBaseUrl()}/ServiceRequest/${referralId}`;
    const result = await axiosInstance.put(url, payload);
    return result?.data;
  }

  static async patchReferral(referralId, payload) {
    const url = `${await this.getBaseUrl()}/ServiceRequest/${referralId}`;
    const result = await axiosInstance.patch(url, payload);
    return result?.data;
  }

  static async getServiceCategories() {
    const url = `${await this.getBaseUrl()}/ValueSet/ocp-service-category`;
    const data = { serviceCategories: [] };
    const params = {};
    try {
      const result = await axiosInstance.get(url, {
        params,
      });
      if (result?.data?.compose?.include?.[0]?.concept) {
        data.serviceCategories = result.data.compose.include[0].concept;
      }
    } catch (error) {
      console.log(error);
    }

    return data;
  }

  static async getCommunications(partOf) {
    try {
      const url = `${await this.getBaseUrl()}/Communication?part-of=${partOf}`;
      const result = await axiosInstance.get(url);
      const found = result?.data?.entry?.map((r) => r?.resource);
      if (found && found.length > 0) {
        return found;
      }
    } catch (error) {
      console.log(error);
    }
    return [];
  }

  static async getDocumentReferences(query) {
    try {
      const url = `${await this.getBaseUrl()}/DocumentReference${query}`;
      const result = await axiosInstance.get(url);
      const found = result?.data?.entry?.map((r) => r?.resource);
      if (found && found.length > 0) {
        return found;
      }
    } catch (error) {
      console.log(error);
    }
    return [];
  }

  static async getDocumentReference(
    docReferenceId,
    rawCCD = false,
    embedBinary = false
  ) {
    try {
      // const url = `${await this.getBaseUrl()}/DocumentReference?_id=${docReferenceId}`;
      const url = `${await this.getBaseUrl()}/DocumentReference/${docReferenceId}`;
      const result = await axiosInstance.get(url);

      const document = result?.data;

      if (embedBinary) {
        let binaryUrl = result?.data?.content?.[0]?.attachment?.url;
        if (!result?.data?.content?.[0]?.attachment?.data && binaryUrl) {
          const matchedUrl = fhirUrlsToReplace?.filter((f) => {
            return binaryUrl.includes(f);
          })?.[0];
          if (matchedUrl) {
            binaryUrl = binaryUrl.replace(matchedUrl, fhirReplacementUrl);
          } else if (binaryUrl?.startsWith("Binary")) {
            binaryUrl = `${fhirReplacementUrl}/${binaryUrl}`;
          }
          // const fullBinaryUrl = binaryUrl?.replace()
          const binaryResult = await axiosInstance.get(binaryUrl);
          if (binaryResult?.data?.data) {
            document.content[0].attachment.data = binaryResult?.data?.data;
          }
        }
      }

      return document;
    } catch (error) {
      console.log(error);
      const message = get(error, "error.response.data.message", error.message);
      return { error: message };
    }
  }

  static async UpdateDocumentReference(docReferenceId, payload) {
    const url = `${await this.getBaseUrl()}/DocumentReference/${docReferenceId}`;
    const result = await axiosInstance.put(url, payload);
    return result?.data;
  }

  // static async linkDocument(referralId, fhirDocument) {
  //   const result = await axiosInstance.post(
  //     `${referralsUrl}/${referralId}/document`,
  //     {
  //       retrievalParameters: ReferralUtils.getRetrievalParameters(fhirDocument),
  //     }
  //   );
  //   return result?.data;
  // }

  /* export const uploadDocument = async ({
    patientID, fhirDocument, file, onUploadProgress
  }) => {
    let formData = new FormData();
    formData.append("file", file);
    formData.append('patientID', patientID);
    formData.append('fhirDocument', JSON.stringify(fhirDocument));
    return axiosInstance.post(
      `${mintProxyUrl}/api/DocumentReference/upload`,
      formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress,
      });
  }; */

  static async createNote(payload) {
    const url = `${await this.getBaseUrl()}/Communication`;
    const result = await axiosInstance.post(url, payload);
    return result?.data;
  }

  static async getReferralServices({
    serviceType,
    addressPostalCode,
    addressState,
    orgName,
    serviceCategory,
    language,
  }) {
    const params = {};
    if (referralCapabilitiesIncludeAll) {
      params["_include"] = "*";
    }
    if (serviceType) {
      params["service-type"] = serviceType;
    }
    if (serviceCategory) {
      params["service-category"] = serviceCategory;
    }
    if (addressPostalCode) {
      params["address-postalcode"] = addressPostalCode;
    }
    if (addressState) {
      params["address-state"] = addressState;
    }
    if (orgName) {
      params["organization:Organization.name"] = orgName;
    }
    if (language) {
      params["language"] = language;
    }

    try {
      const url = `${await this.getBaseUrl()}/HealthcareService`;
      const response = await axiosInstance.get(url, { params });
      return response?.data;
    } catch (error) {
      console.log(error);
    }
    return [];
  }
}
