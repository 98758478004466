import React, { Fragment, useContext, useState, useEffect } from "react";
import { useHistory, useParams, withRouter } from "react-router-dom";
import FhirReferralsList from "./FhirReferralsList";
import { enableServiceDirectory } from "../../../utils/constants";
import styles from "../../../styles/viewer.module.css";
import { Context } from "../../../store/AppStore";
import {
  FhirReferralActions,
  FhirReferralActionButton,
} from "./FhirReferralActions";
import FhirReferralDialog from "./FhirReferralDialog";
import { ThemeProvider } from "@material-ui/core/styles";
import { theme } from "../theme";
import { Container } from "@material-ui/core";
import auth from "../../../utils/auth";
// import ServicesSelectionDialog from "./ServicesSelectionDialog";
import useReferrals from "./useReferrals";
import useOrganizations from "./useOrganizations";
import FhirServicesSelectionDialog from "./FhirServicesSelectionDialog";
import moment from "moment";
import { getUserOrgByName } from "./utils";

const FhirReferralsViewer = ({ showAllReferrals }) => {
  const history = useHistory();
  const [, dispatch] = useContext(Context);
  const { patientId } = useParams();
  const [referralDialogOpen, setReferralDialogOpen] = useState(false);
  const [error, setError] = useState("");
  const [servicesSelectionDialogOpen, setServicesSelectionDialogOpen] =
    useState(false);

  const {
    referrals,
    serviceCategories,
    patient,
    createReferral,
    error: referralsError,
  } = useReferrals({
    patientId,
    showAllReferrals,
  });

  const { organizations: availableOrganizations, error: organizationsError } =
    useOrganizations({});

  const handleReferralClick = (referral) => {
    if (referral) {
      dispatch({ type: "UPDATE_REFERRAL", payload: referral });
      if (showAllReferrals) {
        history.push(`/referrals/${referral.id}`);
      } else {
        history.push(
          `/patient/search/${patientId}/info/1/referrals/${referral.id}`
        );
      }
    }
  };

  const handleNewReferralClick = () => {
    setReferralDialogOpen(true);
  };

  const handleReferralDialogClosed = () => {
    setReferralDialogOpen(false);
  };

  const handleServicesSelectionDialogClick = () => {
    setServicesSelectionDialogOpen(true);
  };

  const handleServicesSelectionDialogClosed = () => {
    setServicesSelectionDialogOpen(false);
  };

  const handleReferralSave = async (referralData) => {
    console.log("DEBUG handleReferralSave referralData: ", referralData);
    console.log("DEBUG user: ", auth.user());
    console.log("DEBUG patient: ", patient);
    const userOrganization = getUserOrgByName(
      auth.user().userinfo.orgName,
      availableOrganizations
    );
    if (!userOrganization) {
      setError(
        `Organization with name ${auth.user().userinfo.orgName} does not exist`
      );
      return "";
    }

    if (!patient) {
      console.log("DEBUG: patient not defined");
      return "";
    }

    try {
      const performer = referralData?.receivingOrganization;
      console.log("DEBUG performer: ", performer);

      const payload = {
        resourceType: "ServiceRequest",
        subject: {
          reference: `Patient/${patient.id}`,
        },
        requester: {
          reference: `Organization/${userOrganization.id}`,
          display: userOrganization.name,
        },
        performer: [
          {
            reference: `Organization/${performer.id}`,
            display: performer.name,
          },
        ],
        status: "active",
        intent: "plan",
        priority: referralData?.urgent ? "urgent" : "routine",
        note: [
          {
            text: referralData?.description,
          },
        ],
        occurrenceDateTime: moment().toISOString(),
      };
      console.log("DEBUG payload: ", payload);
      const response = await createReferral(payload);
      console.log("DEBUG created referral: ", response);
      // await API.createReferral(referral);
      // await loadReferralData();
      // setReferralDialogOpen(false);
      return "";
    } catch (error) {
      return error.message;
    }
  };

  if (error) {
    return (
      <ThemeProvider theme={theme}>
        <Container className={styles.errorContainer}>{error}</Container>
      </ThemeProvider>
    );
  }
  return (
    <Fragment>
      {enableServiceDirectory && (
        <FhirServicesSelectionDialog
          open={servicesSelectionDialogOpen}
          handleClose={handleServicesSelectionDialogClosed}
          handleSave={handleReferralSave}
          // serviceCategories={serviceCategories}
          // organizationNames={organizationNames}
        />
      )}
      <div className={styles.documentViewer}>
        {referrals &&
          availableOrganizations &&
          serviceCategories &&
          !showAllReferrals && (
            <FhirReferralActions>
              {enableServiceDirectory && availableOrganizations ? (
                <FhirReferralActionButton
                  text="Search Directory"
                  action={handleServicesSelectionDialogClick}
                />
              ) : null}
            </FhirReferralActions>
          )}
        {/* {referrals &&
          serviceCategories &&
          organizationNames &&
          !props.showAllReferrals && (
            <ReferralActions>
              <ReferralActionButton
                text="Add a Referral"
                action={handleNewReferralClick}
              />
              {enableServiceDirectory && servicesConfig && organizationNames ? (
                <ReferralActionButton
                  text="Search Directory"
                  action={handleServicesSelectionDialogClick}
                />
              ) : null}
            </ReferralActions>
          )} */}
        <FhirReferralsList
          showAllReferrals={showAllReferrals}
          referrals={referrals}
          onReferralClick={handleReferralClick}
        />
      </div>
    </Fragment>
  );
};

export default withRouter(FhirReferralsViewer);
