import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { Container, Grid, Paper } from "@material-ui/core";
import { AccountCircle, History } from "@material-ui/icons";
import { CategoryList } from "./UserHistory/CategoryList";
import Layout from "./Layout";
import auth from "../utils/auth";
import PatientSearch from "./PatientSearch";
import { patientsHistoryUrl } from "../utils/constants";
import colors from "../utils/colors";
import "../styles/index.css";
import { AuthContext } from "../AuthProvider";
import { provisionPatient } from "../services/MintAPI";
import { PatientProvisioningDialog } from "./PatientViewer";
import { patientDataStore } from "../store/PatientDataStore";

const theme = createMuiTheme({
  overrides: {
    MuiButton: {
      containedPrimary: {
        backgroundColor: colors.baseBackground,
        "&:hover": {
          backgroundColor: colors.hoverColor,
        },
      },
    },
    MuiContainer: {
      root: {
        //textAlign: 'center'
      },
    },
    MuiDrawer: {
      paper: {
        height: "calc(100% - 220px)",
        top: "140px",
        width: "270px",
      },
    },
    PatientSelector: {
      center: {
        margin: "30px",
      },
    },
  },
});

const PatientsHome = () => {
  const token = useContext(AuthContext);
  const history = useHistory();
  const isAuthenticated = auth.isAuthenticated(token);
  const [patientInfo, setPatientInfo] = useState(null);
  const [dialogPatientData, setDialogPatientData] = useState({});
  const [dialogFhirPatientData, setDialogFhirPatientData] = useState({});
  const [isProvisionDialogOpen, setProvisionDialogOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState(
    "Add selected Patient to your Organization?"
  );
  const [dialogShowOnlyOkButton, setDialogShowOnlyOkButton] = useState(false);

  const handleProvisionDialogSave = async (
    displayPatient,
    fhirPatient,
    mrn
  ) => {
    try {
      displayPatient.id = await provisionPatient(fhirPatient, mrn);
      setProvisionDialogOpen(false);
      await navigateToPatientPageIfApplicable(displayPatient);
    } catch (error) {
      return error.message;
    }
  };

  const closeProvisionDialog = () => {
    setProvisionDialogOpen(false);
  };

  const openProvisionDialogIfApplicable = (
    patientData,
    patientTableOriginalData
  ) => {
    const isOpenIdUser =
      process.env.REACT_APP_DEVELOPMENT_MODE === "true" ||
      auth.loggedInUser?.["authType"] === "OIDC";
    console.log("DEBUG isOpenIdUser: ", isOpenIdUser);
    if (!patientData.id && patientTableOriginalData) {
      if (!isOpenIdUser) {
        setDialogTitle(
          [
            `Can't post Patient `,
            `to the originating system. Please use the following information`,
            `to register it manually:`,
          ].join(" ")
        );
      }
      const fhirPatient =
        patientTableOriginalData[patientData["arrayIndex"]].resource;
      setDialogShowOnlyOkButton(!isOpenIdUser);
      setDialogPatientData(patientData);
      setDialogFhirPatientData(fhirPatient);
      setProvisionDialogOpen(true);
    }
  };

  const handlePatientClick = async ({
    patientData,
    patientTableOriginalData,
  }) => {
    openProvisionDialogIfApplicable(patientData, patientTableOriginalData);
    await navigateToPatientPageIfApplicable(patientData);
  };

  const navigateToPatientPageIfApplicable = async (patientData) => {
    const patientId = patientData.id || patientData.patientid;
    if (patientId) {
      const patient = {
        name:
          patientData.patientname ||
          `${patientData.firstName} ${patientData.lastName}`,
        id: patientData.patientid || patientData.id,
        birthDate: patientData.birthDate || "N/A",
      };
      setPatientInfo(patient);
      await patientDataStore.getPatientData(patientId);
      history.push(`/patient/${patient.id}/info/1`);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <PatientProvisioningDialog
        patientData={dialogPatientData}
        fhirPatient={dialogFhirPatientData}
        open={isProvisionDialogOpen}
        title={dialogTitle}
        handleSave={handleProvisionDialogSave}
        handleClose={closeProvisionDialog}
        showOnlyOkButton={dialogShowOnlyOkButton}
        useManualMrn={true}
      />
      <Layout openFile={false} patientData={patientInfo}>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={10}>
              <Container className="container-patients">
                <PatientSearch
                  handlePatientOnClick={handlePatientClick}
                  auth={auth}
                />
              </Container>
            </Grid>
            <Grid item xs={2}>
              <Paper className="container-history">
                <CategoryList
                  categoryName="History"
                  dataPath="recentPatients"
                  labelProperty="patientname"
                  dataUrl={patientsHistoryUrl}
                  itemLimit={10}
                  token={auth.token}
                  authenticated={isAuthenticated}
                  CategoryIconComponent={History}
                  ItemIconComponent={AccountCircle}
                  onItemClick={handlePatientClick}
                  //useMockData={mockData}
                ></CategoryList>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Layout>
    </ThemeProvider>
  );
};

export default PatientsHome;
